import { message } from "antd";
import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/backend/${process.env.REACT_APP_API_KEY}`;

class TableServices {

    // Create Collection
    createTable({user, values}) {
        if(user){
            let configuration = {
                credentials: 'include',
                method: "post",
                url: API_URL + `/collection`,
                headers: {"Authorization" : `Bearer ${user.access}`},
                data:values
            };
    
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response
                error = new Error();
            });
        }
        else{
            message.error('Must be logged in to create a table.')
        }
    };

    // Add Table Data
    addTableValues({user, values}) {
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/collection`,
            headers: {"Authorization" : `Bearer ${user.access}`},
            data:values
        };
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };

    // Edit Table
    editTable({user, sk, values}) {
        const configuration = {
            credentials: 'include',
            method: "patch",
            url: API_URL + `/collection/${sk}`,
            headers: {"Authorization" : `Bearer ${user.access}`},
            data:values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };

    // Get Collection
    getTable({user, sk}) {
        const configuration = {
            credentials: 'include',
            method: "get",
            headers: {"Authorization" : `Bearer ${user.access}`},
            url: API_URL + `/collection/${sk}`
        };
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };

    // Get Data
    getTableData({page=0, limit=10, user, sk, ordering='-updated_at'}) {
        
        if(user && sk){
            const offset = limit * page;
    
            var configAPIUrl = API_URL
            if (offset > 0){configAPIUrl = API_URL + `/collection/data?sk=${sk}&limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
            else { configAPIUrl = API_URL + `/collection/data?sk=${sk}&limit=${limit}&ordering=${ordering}&is_active=True` };
            
            const configuration = {
                credentials: 'include',
                method: "get",
                url: configAPIUrl,
                headers: {"Authorization" : `Bearer ${user.access}`},
            };
    
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response
                error = new Error();
            });
        }
    };

    // Get User Tables
    getUserTableList({page=0, user, ordering='-updated_at', is_complete=false}) {

        const limit = 12;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/collection/user?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/collection/user?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(is_complete){
            configAPIUrl = configAPIUrl + '&status=complete'
        }
        
        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    getCollectionApps({page=0, limit=12, user, sk, ordering='-updated_at'}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/collection/apps?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/collection/apps?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(sk){
            configAPIUrl = configAPIUrl + `&sk=${sk}`
        }
        
        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
            headers: {"Authorization" : `Bearer ${user.access}`},
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    // Get Batch
    getBatch({user, id}) {
        const configuration = {
            credentials: 'include',
            method: "get",
            headers: {"Authorization" : `Bearer ${user.access}`},
            url: API_URL + `/batch/${id}`
        };
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };

    getBatchProgress({user, sk}) {
        const configuration = {
            credentials: 'include',
            method: "post",
            headers: {"Authorization" : `Bearer ${user.access}`},
            url: API_URL + `/batch/progress/${sk}`
        };
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    };

    // Queries



    // LLM



    // 

}
export default new TableServices();