import { LoadingOutlined } from '@ant-design/icons';

function LoadingPage() {

    return (
        <div className='absolute top-0 bottom-0 right-0 left-0 bg-[#1B191C]'>
            <div className='h-full w-full flex align-center justify-center pb-24'>
                <LoadingOutlined style={{fontSize:'40px',color:'#1677ff'}} />
            </div>
        </div>
    )
}

export default LoadingPage;