import { createContext, useContext, useMemo } from "react";
import { Outlet } from 'react-router-dom';
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { useAuth } from "../../../utils/useAuth";
import { message } from "antd";

const AppContext = createContext();

export const AppProvider = () => {
    const {user} = useAuth();
    const [app,setApp] = useLocalStorage('app',false);
    const [appData,setAppData] = useLocalStorage('appData',false);
    const [appType,setAppType] = useLocalStorage('appType',false);
    const [isSiderVisible,setIsSiderVisible] = useLocalStorage('siderToggle',false);
    const [siderTab,setSiderTab] = useLocalStorage('siderTab',false);

    const openSider = (value) => {
        if(user){
            setSiderTab(value);
            setIsSiderVisible(true);
        }
        else{
            message.info('Login for more access.');
        }
    }
    const resetData = () => {
        setApp(false);
        setAppType(false)
        setAppData(false);
    }

    const value = useMemo(
        () => ({
            app,setApp,
            appData,setAppData,
            appType,setAppType,
            isSiderVisible,setIsSiderVisible,
            siderTab,setSiderTab,openSider,resetData,
        }),[app,appData,appType,isSiderVisible,siderTab]
    );

    return(
        <AppContext.Provider value={value}>
            <Outlet />
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};