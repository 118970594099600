import React, {Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from 'antd';
import LoadingPage from './containers/landing/LoadingPage';
import { TableProvider } from './containers/table/components/TableContext';
import { MainProvider } from './components/Context/MainContext';
import { AuthProvider } from './utils/useAuth';
import { AppProvider } from './containers/apps/components/AppContext';
import { EventTrackingProvider } from './components/Context/EventTrackingContext';
import "antd/dist/reset.css";
import '../src/style/index.css';
import './output.css';

const AppPage = React.lazy(() => import('./containers/apps/pages/AppPage'));
const AppsPage = React.lazy(() => import('./containers/apps/pages/AppsPage'));
const AppPagePublic = React.lazy(() => import('./containers/apps/pages/AppPagePublic'));
const HomePage = React.lazy(() => import('./containers/landing/HomePage'));
const DashboardLayout = React.lazy(() => import('./containers/dashboard/DashboardLayout'));
const DashboardHomePage = React.lazy(() => import('./containers/dashboard/pages/DashboardHomePage'));
const TablesPage = React.lazy(() => import( './containers/table/pages/TablesPage'));
const TablePage = React.lazy(() => import('./containers/table/pages/TablePage'));
const LoginPage = React.lazy(() => import('./containers/landing/LoginPage'));

const { Content } = Layout;

function App() {  
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <MainProvider>
            <EventTrackingProvider>
              <Layout>
                <Content>
                  <Routes>
                    <Route path='/' element={<Suspense fallback={<LoadingPage />} ><HomePage /></Suspense>} />
                    <Route path='/login' element={<Suspense fallback={<LoadingPage />} ><LoginPage /></Suspense>} />
                    <Route path='/register' element={<Suspense fallback={<LoadingPage />} ><LoginPage type='register' /></Suspense>} />
                    <Route path='dashboard' element={<Suspense fallback={<LoadingPage />} ><DashboardLayout /></Suspense>}>
                      <Route path='' element={<Suspense fallback={<LoadingPage />} ><DashboardHomePage /></Suspense>} />
                      <Route path='tables' element={<Suspense fallback={<LoadingPage />} ><TablesPage /></Suspense>} />
                      <Route path='apps' element={<Suspense fallback={<LoadingPage />} ><AppsPage /></Suspense>} />
                    </Route>
                    <Route path='table' element={<Suspense fallback={<LoadingPage />} ><TableProvider /></Suspense>}>
                      <Route path=':id' element={<Suspense fallback={<LoadingPage />} ><TablePage /></Suspense>} />
                    </Route>
                    <Route path='app' element={<Suspense fallback={<LoadingPage />} ><AppProvider /></Suspense>}>
                      <Route path=':id' element={<AppPage />} />
                      <Route path=':id/public' element={<AppPagePublic />} />
                    </Route>
                  </Routes>
                </Content>
              </Layout>
            </EventTrackingProvider>
          </MainProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
