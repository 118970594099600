import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { useMainContext } from "../../../components/Context/MainContext";
import { message } from "antd";
import TableServices from "../utils/TableServices";
import { useAuth } from "../../../utils/useAuth";
import { Outlet } from "react-router-dom";

const TableContext = createContext();

export const TableProvider = () => {
    const {openRegister} = useMainContext();
    const {user,refresh} = useAuth();
    const [isSiderVisible,setIsSiderVisible] = useLocalStorage('siderToggle',false);
    const [siderTab,setSiderTab] = useLocalStorage('siderTab',false);
    const [table,setTable] = useLocalStorage('table',{});
    const [tableData,setTableData] = useLocalStorage('tableData',[]);
    const [tableFullData,setTableFullData] = useLocalStorage('tableData',[]);
    const [tableEdits,setTableEdits] = useLocalStorage('tableEdits',[]);
    const [tableStatus,setTableStatus] = useLocalStorage('tableStatus',null);
    const [tableRowSelect,setTableRowSelect] = useLocalStorage('tableRowSelect',null);

    const openSider = (value) => {
        if(['Import'].includes(value)){
            setSiderTab(value);
            setIsSiderVisible(true);
        }
        else if(['Apps','Context','Embeddings','Edit'].includes(value)){
            if(user){
                setSiderTab(value);
                setIsSiderVisible(true);
            }
            else{
                message.info('Create Account to Access this Feature.');
                setTimeout(() => {
                    openRegister();
                },[500]);
            }
        }
        else{
            if(user){
                message.info('Feature In Beta - Contact us for Early Access.')
            }
            else{
                message.info('Create Account to Access this Feature.');
                setTimeout(() => {
                    openRegister();
                },[500]);
            }
        }
    }
    const closeSlider = () => {
        setTableRowSelect(null);
        setIsSiderVisible(false);
    }
    const editValue = ({key,value}) => {
        setTableStatus('save');
        setTable(prev => {return {...prev,[key]:value}});
        if(tableEdits.includes(key) == false){
            setTableEdits(prev => {return [...prev,key]});
        }
    }
    const saveTable = async () => {
        setTableStatus('saving');

        if(table){
            const newValues = tableEdits.reduce((result, key) => {
                result[key] = table[key];
                return result;
            }, {});

            // Get Table Details
            let tableResponse = await TableServices.editTable({user:user,sk:table.sk,values:newValues});
            if(tableResponse.status == 401){
                const newUser = await refresh();
                tableResponse = await TableServices.editTable({user:newUser,sk:table.sk,values:newValues});
            }
            else if (tableResponse.status == 200){
                setTable(tableResponse.data);
                message.success('Changes have been Saved!');
            }
        }
        setTableStatus(null);
        setTableEdits([]);
    }
    const resetTableContext = () => {
        setIsSiderVisible(false);
        setSiderTab('Import');
        setTable(null);
        setTableData([]);
        setTableEdits([]);
        setTableStatus(null);
    }    
    const value = useMemo(
        () => ({
            isSiderVisible,setIsSiderVisible,openSider,closeSlider,
            siderTab,setSiderTab,
            table,setTable,
            tableData,setTableData,
            tableEdits,setTableEdits,
            tableStatus,setTableStatus,
            tableRowSelect,setTableRowSelect,
            tableFullData,setTableFullData,
            resetTableContext,
            editValue,saveTable,
        }),[isSiderVisible,siderTab,tableData,tableFullData,tableRowSelect,tableEdits,tableStatus,table]
    );

    return(
        <TableContext.Provider value={value}>
            <Outlet/>
        </TableContext.Provider>
    );
};

export const useTableContext = () => {
    return useContext(TableContext);
};