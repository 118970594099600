import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-JCPDDS7T5C"; // your Measurement ID

ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`OnlyVectors - Vectors Made Easy`}</title>
      <meta property="og:title" content={`OnlyVectors - Vectors Made Easy`}/>
      <meta name="description" content={`OnlyVectors - Vectors Made Easy`} />
      <link rel="canonical" href="https://www.onlyvectors.com" />
    </Helmet>
    <App />
  </HelmetProvider>
);

reportWebVitals();
